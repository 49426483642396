import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { MomentModule } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    NgbPopoverModule,
    NgbTooltipModule,
    DateProxyPipe,
    MomentModule,
    TranslateModule,
  ],
  selector: 'table-dateTime',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @defer(on viewport){@if(data){
    <div
      [ngbTooltip]="
        data
          | date
            : tooltipPipeArgs || 'full'
            : undefined
            : timezoneOffset || undefined
      "
      class="text-truncate"
      [innerHTML]="
        pipeType === 'date'
          ? (data
            | date
              : pipeArgs || 'medium'
              : undefined
              : timezoneOffset || undefined)
          : (data | amTimeAgo)
      "
    ></div>
    }} @placeholder {
    <div
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    >
      <span class="text-secondary visually-hidden">
        {{ 'LOADING' | translate }}...
      </span>
    </div>
    }
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
})
export class TableDateTimeComponent {
  @Input() data?: string;
  @Input() pipeType: 'date' | 'amTimeAgo' = 'date';
  @Input() pipeArgs?: string;
  @Input() tooltipPipeArgs?:
    | 'short'
    | 'medium'
    | 'long'
    | 'full'
    | 'fullDate'
    | 'shortDate'
    | 'mediumDate'
    | 'longDate'
    | 'fullTime'
    | 'shortTime'
    | 'mediumTime'
    | 'longTime';
  @Input() timezoneOffset?: string;
}
